import React from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FormikProps } from 'formik';
import { Button, ButtonContainer, Fieldset, Form, Paragraph, SectionHeading } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, preventSubmit } from '@cp-shared-8/frontend-ui';
import { MethodsOfPayment, nowCPDate, parseCPDate } from '@cp-uk/common';
import { textWithComponents } from 'utils';
import { View } from 'components/view/View';
import { ContractDescription } from 'components/contract-description/ContractDescription';
import { ValidatedRadioButtons } from 'components/validated-radio-buttons/ValidatedRadioButtons';
import { ValidatedInputWithCleave } from 'components/validated-input/ValidatedInput';
import { ArrearsBalanceParagraph } from '../arrears-balance-paragraph/ArrearsBalanceParagraph';
import { AlternativeNotification } from '../alternative-notification/AlternativeNotification';
import { PayCommonViewProps, SummaryItem } from '../types';
import {
    dateOfPaymentDisabled,
    dateOfPaymentIsMandatory,
    getMethodOfPaymentRadioButtons,
    getActionNotificationContent,
} from '../helpers';
import { validationSchema } from './PayInInstalmentsValidation';
import { buildInitialValues, buildSelections, buildSummaryItem } from './helpers';
import { PayInInstalmentsFormValues, PayInInstalmentsSelections } from './types';

export type PayInInstalmentsViewProps = PayCommonViewProps & {
    currentSelections: PayInInstalmentsSelections | undefined;
    onContinue: (selections: PayInInstalmentsSelections, summaryItem: SummaryItem) => void;
};

export const PayInInstalmentsView: React.FC<PayInInstalmentsViewProps> = ({
    contractDescription,
    totalArrears,
    changeBankAccountInProgress,
    changePaymentDateInProgress,
    sortCode,
    accountNumber,
    lastBilledPaymentDate,
    nextScheduledPaymentDate,
    currentOptionTitle,
    currentSelections,
    onBack,
    onContinue,
}) => {
    const { t } = useTranslation('request-additional-help-pay-in-instalments-view');

    // TODO: Analytics...

    const todayMoment = nowCPDate().toMoment().startOf('day');
    const minValidDate = todayMoment.clone().add(1, 'day');
    const maxValidDate = todayMoment.clone().add(1, 'month');
    const inBillingPeriod = parseCPDate(lastBilledPaymentDate).toMoment().isSameOrAfter(todayMoment, 'day');
    const noRegularPayments = !nextScheduledPaymentDate;

    const pound = '£';

    const directDebitDisabled = changeBankAccountInProgress || changePaymentDateInProgress || noRegularPayments;

    const methodOfPaymentRadioButtons = getMethodOfPaymentRadioButtons(t, directDebitDisabled);

    const getLetUsKnowHowAndWhenParagraphText = (): React.ReactNode => {
        let keyFragment: string;
        if (changeBankAccountInProgress && changePaymentDateInProgress) {
            keyFragment = 'changeBankAccountAndPaymentDateInProgress';
        } else if (changeBankAccountInProgress) {
            keyFragment = 'changeBankAccountOnlyInProgress';
        } else if (changePaymentDateInProgress) {
            keyFragment = 'changePaymentDateOnlyInProgress';
        } else if (inBillingPeriod) {
            if (noRegularPayments) {
                keyFragment = 'inBillingPeriodNoRegularPayments';
            } else {
                keyFragment = 'inBillingPeriod';
            }
        } else {
            if (noRegularPayments) {
                keyFragment = 'noRegularPayments';
            } else {
                keyFragment = 'normal';
            }
        }

        return textWithComponents(t, `paragraphs.letUsKnowHowAndWhen.${keyFragment}`, undefined, {
            nextScheduledPaymentDate,
            totalArrears: (totalArrears ?? 0) > 0 ? totalArrears : undefined,
        });
    };

    const initialValues = buildInitialValues(currentSelections);

    const resetDateOfFirstPayment = ({ setFieldValue, setFieldTouched }: FormikProps<PayInInstalmentsFormValues>): void => {
        setFieldValue('dateOfFirstPayment', '', false);
        setFieldTouched('dateOfFirstPayment', false, false);
    };

    const onMethodOfPaymentChange = (
        { target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>,
        formik: FormikProps<PayInInstalmentsFormValues>,
    ): void => {
        if (newValue === 'directDebit') {
            resetDateOfFirstPayment(formik);
        }
    };

    const onBackClick = (): void => {
        // TODO: Analytics...
        onBack();
    };

    return (
        <View testId={'payInInstalmentsView'}>
            <SectionHeading level={2} testId={'viewHeader'}>
                {t('subHeading', { currentOptionTitle })}
            </SectionHeading>
            <ArrearsBalanceParagraph totalArrears={totalArrears} />
            <ContractDescription className={'u-mb'} contractDescription={contractDescription} />
            <Paragraph className={'u-mb-large'} testId={'introductionParagraph'}>
                {t('paragraphs.introduction')}
            </Paragraph>
            <Paragraph testId={'doesNotFeelRightParagraph'}>{t('paragraphs.doesNotFeelRight')}</Paragraph>
            <Paragraph className={'u-mb-large'} testId={'happyToProceedParagraph'}>
                {textWithComponents(t, 'paragraphs.happyToProceed')}
            </Paragraph>
            <Paragraph testId={'letUsKnowHowAndWhenParagraph'}>{getLetUsKnowHowAndWhenParagraphText()}</Paragraph>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema(t, totalArrears, minValidDate, maxValidDate)}
                onSubmit={(values): void => {
                    const selections = buildSelections(t, nextScheduledPaymentDate, values);

                    const summaryItem = buildSummaryItem(t, nextScheduledPaymentDate, values);

                    onContinue(selections, summaryItem);
                }}
            >
                {(formik: FormikProps<PayInInstalmentsFormValues>): React.ReactNode => (
                    <Form className={'uk-request-additional-help-pay-in-instalments-form'} onSubmit={preventSubmit}>
                        <Fieldset>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [6],
                                        delimiter: '',
                                    }}
                                    className={'input-money'}
                                    label={t('additionalAmount.label')}
                                    tooltip={t('additionalAmount.tooltip')}
                                    name={'additionalAmount'}
                                    testId={'additionalAmount'}
                                    addonText={pound}
                                    reversed
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedRadioButtons
                                    className={'u-indent u-mb-xsmall'}
                                    label={t('methodOfPayment.label')}
                                    radioButtons={methodOfPaymentRadioButtons}
                                    name={'methodOfPayment'}
                                    testId={'methodOfPayment'}
                                    handleChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                        onMethodOfPaymentChange(e, formik);
                                    }}
                                    isMandatory
                                />
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <ValidatedInputWithCleave
                                    cleaveOptions={{
                                        numericOnly: true,
                                        blocks: [2, 2, 4],
                                        delimiter: '/',
                                    }}
                                    className={'input-date'}
                                    label={t('dateOfFirstPayment.label')}
                                    tooltip={t('dateOfFirstPayment.tooltip', { minValidDate, maxValidDate })}
                                    placeholder={t('dateOfFirstPayment.placeholder')}
                                    name={'dateOfFirstPayment'}
                                    testId={'dateOfFirstPayment'}
                                    isMandatory={dateOfPaymentIsMandatory(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                    disabled={dateOfPaymentDisabled(
                                        formik.values.methodOfPayment as MethodsOfPayment,
                                    )}
                                />
                            </Fieldset.Row>
                            {!!formik.values.methodOfPayment && (
                                <Fieldset.Row>
                                    <Notification
                                        status={NotificationStatus.info}
                                        testId={'actionNotification'}
                                        text={' '}
                                    >
                                        {getActionNotificationContent(t, formik.values.methodOfPayment as MethodsOfPayment, sortCode, accountNumber)}
                                    </Notification>
                                </Fieldset.Row>
                            )}
                            <Fieldset.Row>
                                <ButtonContainer nav>
                                    <Button type={'button'} testId={'backButton'} onClick={onBackClick} secondary>
                                        {t('translation:editableSectionNav.back')}
                                    </Button>
                                    <Button type={'button'} testId={'continueButton'} onClick={formik.submitForm}>
                                        {t('translation:editableSectionNav.continue')}
                                    </Button>
                                </ButtonContainer>
                            </Fieldset.Row>
                            <Fieldset.Row>
                                <AlternativeNotification />
                            </Fieldset.Row>
                        </Fieldset>
                    </Form>
                )}
            </Formik>
        </View>
    );
};
