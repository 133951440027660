import { Action, configureStore, EnhancedStore, ThunkAction } from '@reduxjs/toolkit';

import rootReducer, { RootState } from './rootReducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createStore(): EnhancedStore<any, any> {
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: { warnAfter: 128 },
                serializableCheck: { warnAfter: 128 },
            }),
    });
}

const store = createStore();

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default;
        store.replaceReducer(newRootReducer);
    });
}

export type AppDispatch = typeof store.dispatch;
// TODO Think about moving it out to shared packages as AppThunk<T> type
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;

export type AppStore = typeof store;

export type AppState = RootState;
